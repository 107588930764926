import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ADMINISTRATOR, CUSTOMER_SUCCESS, UserService } from '../../shared/services/user.service';
import { Recharge, INVOICE_FAILED } from './../../models/Recharge';
import { User } from './../../models/User';
import { ConfirmationService } from './../../shared/components/confirmation-modal/confirmation.service';
import { ToastService } from './../../shared/components/toast/toast.service';
import { PaginatedResult } from './../../shared/model/paginated-result';
import { AuthService } from './../../shared/services/auth.service';
import { RechargesService } from './../../shared/services/recharges.service';
import { UserCreditInfo } from 'src/app/models/UserCreditInfo';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RechargeFilter } from '../../models/RechargeFilter';

@Component({
  selector: 'app-recharges',
  templateUrl: './recharges.component.html',
  styleUrls: ['./recharges.component.scss'],
})
export class RechargesComponent implements OnInit, OnDestroy {
  @ViewChild('modalDelete', {static: true}) modalDelete: ElementRef<any>;
  @ViewChild('modalTransfer', {static: true}) modalTransfer: ElementRef<any>;


  deleteForm: UntypedFormGroup;
  transferForm: UntypedFormGroup;

  addPage = 'new';
  page = 1;
  perPage = 10;
  user: User;
  users: User[];

  pixImage: string;
  pixCode: string;

  invalidTime: boolean;

  startDate: Date | null = null;
  endDate: Date | null = null;

  months: any[] = [];

  text: string;
  credit_info: UserCreditInfo;

  little_warning: string;

  invertedOrder = true;
  orderBy = 'created_at';
  author: string = '';

  recharge: Recharge;

  result: PaginatedResult<Recharge[]>;
  subscriptions: Subscription[] = [];

  get administrator(): boolean {
    return this.user.profile_id === ADMINISTRATOR;
  }

  get customer_success(): boolean {
    return this.user.profile_id === CUSTOMER_SUCCESS;
  }

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private authService: AuthService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private rechargesService: RechargesService,
    private confirmationService: ConfirmationService
  ) { }

  private toISODate(date: Date): string {
    const timeZone = 'America/Sao_Paulo';
    return formatDate(date, 'yyyy-MM-ddTHH:mm:ssZ', 'en-US', timeZone);
  }

  ngOnInit(): void {
    this.user = this.authService.getUser();
  
    const today = new Date();
    this.startDate = new Date(today);
    this.startDate.setDate(today.getDate() - 1);
  
    this.endDate = new Date(today);
    this.endDate.setDate(today.getDate() + 1);
  
    if ([ADMINISTRATOR, CUSTOMER_SUCCESS].includes(this.user.profile_id)) {
      this.userService.listAll().subscribe((users) => (this.users = users));
    }
  
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params) => {
        const { page, per_page, start_date, end_date, orderBy, invertedOrder } = params;
  
        this.page = page || this.page;
        this.perPage = per_page || this.perPage;
  
        this.startDate = start_date ? new Date(start_date) : this.startDate;
        this.endDate = end_date ? new Date(end_date) : this.endDate;
  
        this.orderBy = orderBy || this.orderBy;
        this.invertedOrder = invertedOrder !== undefined ? JSON.parse(invertedOrder) : this.invertedOrder;
        this.listPaginated();
      }),
      this.authService.userChanged.subscribe(() => this.listPaginated())
    );
  
    this.deleteForm = this.formBuilder.group({
      reason: [Validators.required],
    });
  
    this.transferForm = this.formBuilder.group({
      reason: [Validators.required],
      to_user: [Validators.required, Validators.minLength(36)],
    });
  }
  
  isFailed(status: number): boolean {
    return status === INVOICE_FAILED;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  listPaginated(): void {
    const filter = new RechargeFilter();
    filter.orderDirection = this.invertedOrder;
    filter.order = this.orderBy;
    filter.author = this.author;
    filter.text = this.text;

    if (this.startDate) {
        filter.startDate = this.toISODate(this.startDate);
    }

    if (this.endDate) {
        filter.endDate = this.toISODate(this.endDate);
    }

    if (this.administrator || this.customer_success) {
        this.addPage = 'add';
    }

    this.subscriptions.push(
        this.rechargesService
            .listPaginated(this.page, this.perPage, filter)
            .subscribe((result) => (this.result = result))
    );
  }

  pageChanged(page: number): void {
    const queryParams: any = { page, per_page: this.perPage };
    if (this.text) {
        queryParams['text'] = this.text;
    }

    if (this.startDate) {
        queryParams['start_date'] = this.toISODate(this.startDate);
    }

    if (this.endDate) {
        queryParams['end_date'] = this.toISODate(this.endDate);
    }

    if (
        (this.startDate && this.endDate && this.startDate <= this.endDate) ||
        (!this.startDate && this.endDate) ||
        (this.startDate && !this.endDate)
    ) {
        this.invalidTime = false;
        this.router.navigate(['/recharges'], { queryParams });
    } else {
        this.invalidTime = true;
    }
  }

  invertOrder(): void {
    this.router.navigate(['.'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        invertedOrder: !this.invertedOrder
      },
      queryParamsHandling: 'merge'
    });
  }

  filterChanged() {
    this.router.navigate(['.'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        orderBy: this.orderBy,
        author: this.author
      },
      queryParamsHandling: 'merge'
    })
  }

  showQRCode(modalQRCode: any, recharge: Recharge): any {
    this.subscriptions.push(
      this.rechargesService.getPixImage(recharge.id).subscribe((pixImage) => {
        this.pixImage = pixImage.url_qrcode_pix;
        this.pixCode = pixImage.pix_code;
        this.modalService.open(modalQRCode);
      })
    );
  }

  pixCopy(pixCode: string): void {
    navigator.clipboard.writeText(pixCode);
    this.toastService.success('Código PIX copiado para a área de transferência!');
  }

  confirmCredits(recharge: Recharge): void {
    this.confirmationService.show(
      `Deseja realmente confirmar os créditos do usuário <b>${recharge.user.name}</b>, valor <b>R$ ${recharge.total}</b>?`,
      () => this.confirm(recharge.id)
    );
  }

  confirm(id: string): void {
    this.subscriptions.push(
      this.rechargesService.confirm(id).subscribe((recharge) => {
        this.toastService.success('Créditos confirmados com sucesso!');
        const index = this.result.data.findIndex((r) => r.id === recharge.id);
        this.result.data[index].confirmated_at = recharge.confirmated_at;
      })
    );
  }

  confirmDelete(recharge: Recharge): void {
    this.confirmationService.show(
      `Deseja realmente excluir a recarga de créditos do usuário <b>${recharge.user.name}</b>, valor <b>R$ ${recharge.total}</b>?`,
      () => this.delete(recharge.id)
    );
  }

  delete(id: string, reason?: string): void {
    this.modalService.dismissAll();
    this.subscriptions.push(
      this.rechargesService.delete(id, reason).subscribe(() => {
        this.toastService.success('Recarga de créditos excluída com sucesso!');
        this.listPaginated();
      })
    )
  }

  cancel() {
    const reason = this.deleteForm.get('reason').value;

    if (!this.deleteForm.valid) {
      return this.toastService.error('Por favor, preencha os campos.');
    }

    this.delete(this.recharge.id, reason);
  }

  transfer (): void {
    const reason = this.transferForm.controls.reason.getRawValue();
    const to_user = this.transferForm.controls.to_user.getRawValue();

    if (!reason || !to_user) {
      return this.toastService.error('Por favor, preencha os campos.');
    }

    if (to_user == this.recharge.user_id) {
      return this.toastService.error('O usuário selecionado é inválido.')
    }

    if (this.transferForm && this.transferForm.valid) {
      this.modalService.dismissAll();
      this.rechargesService.transfer(this.recharge.id, reason, to_user).subscribe((response) => {
        if (response.success) {
          this.toastService.success('Recarga de créditos transferida com sucesso!');
          this.listPaginated();
        }else{
          this.toastService.error('Falha ao transferir recarga de créditos!')
        }
      })
    }
  }

  getTotalCredits(userId: string, recharge: Recharge) {
    this.rechargesService.getUserCreditInfo(userId).subscribe((creditInfo) => {
      this.credit_info = creditInfo;
      let realValue = 0;
      if (creditInfo.credits >= recharge.credits) {
        realValue = recharge.credits;
      } else {
        realValue = creditInfo.credits;
      }
    });
  }

  newActionModal(action: string, modal: any, recharge: Recharge): void {
    if (action == 'transfer') {
      this.getTotalCredits(recharge.user_id, recharge);
    }
    this.deleteForm.reset();
    this.transferForm.reset();
    this.recharge = recharge;
    this.modalService.open(modal);
  }
}
