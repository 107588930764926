import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Profile } from 'src/app/models/Profile';
import { User } from 'src/app/models/User';
import { ProfileService } from '../../../shared/services/profile.service';
import { ToastService } from './../../../shared/components/toast/toast.service';
import { UserService } from './../../../shared/services/user.service';
import { InputCompareValidator } from './../../../shared/validators/input-compare.validator';
import { AGENCY_CLIENT, PARTNER_CLIENT } from '../../../shared/services/user.service';
import {ProviderAccountService} from '../../../shared/services/provider-account.service';
import {ShortenerService} from '../../../shared/services/shortener.service';
import {ProviderAccount} from '../../../models/ProviderAccount';
import { Shortener } from '../../../models/Shortener';
import { UtilService } from 'src/app/shared/services/util.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {
  @ViewChild('modalBetaConfirmation') modalBetaConfirmation: TemplateRef<any>;

  get title(): string {
    if (this.userId) {
      return 'Alterar Usuário';
    }

    return 'Novo usuário';
  }

  userId: string;
  active: boolean;
  massiveSmsEnabled: boolean;
  revisionSmsEnabled: boolean;
  contentTagEnabled: boolean;
  tokenVerifyEnabled: boolean;
  partnersAPIEnabled: boolean;
  exportLeads: boolean;
  analyticsSync: boolean;
  fakeRequest: boolean;
  broadcastSendInterval: boolean;
  generatePassword: boolean;
  pixPriority: boolean;
  operationType: string;
  creditPaymentEnabled: boolean;
  userForm: UntypedFormGroup;
  profiles$: Observable<Profile[]>;
  providerAccounts: ProviderAccount[] = [];
  shorteners$: Observable<Shortener[]>;
  beta: boolean;
  subscriptions: Subscription[] = [];
  emailConfirmation: string;

  operationTypes: any[] = [];

  get showCommission() {
    const profileId = this.userForm.get('profile_id').value;
    return profileId === AGENCY_CLIENT || profileId === PARTNER_CLIENT;
  }

  get user() {
    return this.userForm.getRawValue() as User;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private providerAccountService: ProviderAccountService,
    private shortenerService: ShortenerService,
    private profileService: ProfileService,
    private toastService: ToastService,
    private UtilService: UtilService, 
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.userForm = this.fb.group({
      id: [''],
      name: ['', [Validators.required, Validators.minLength(5)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      commission: ['', null],
      profile_id: ['', [Validators.required]],
      password: ['', null],
      pix_key: ['', null],
      pix_priority: ['', null],
      automation_provider_account_id: ['', null],
      broadcast_provider_account_id: ['', null],
      shortener_id: ['', null],
      password_confirmation: ['', null, InputCompareValidator.compare('password')],
      administrator: [false],
      analytics_sync: [false],
      operation_type: [''],
      credit_payment_enabled: [false],
      massive_sms_enabled: [false],
      revision_sms_enabled: [false],
      content_tag_enabled: [false],
      sms_token_validated: [false],
      beta: [false],
      api_enabled: [false]
    });

    this.subscriptions.push(
      this.userForm.get('password')
        .valueChanges
        .subscribe(() => this.userForm.patchValue({ password_confirmation: '' }))
    );

    this.subscriptions.push(
      this.route.params.subscribe(params => {
        this.userId = params.id;
        if (params.id) {
          this.userService.findById(params.id).subscribe(user => {
            this.userForm.patchValue(user);
            this.userId = user.id;
            this.active = user.active;
            this.creditPaymentEnabled = user.credit_payment_enabled;
            this.analyticsSync = user.analytics_sync;
            this.fakeRequest = user.fake_request;
            this.exportLeads = user.leads_export;
            this.broadcastSendInterval = user.broadcast_send_interval;
            this.exportLeads = user.leads_export;
            this.pixPriority = user.pix_priority;
            this.massiveSmsEnabled = user.massive_sms_enabled;
            this.revisionSmsEnabled = user.revision_sms_enabled;
            this.contentTagEnabled = user.content_tag_enabled;
            this.tokenVerifyEnabled = user.sms_token_validated;
            this.partnersAPIEnabled = user.api_enabled;
            this.UtilService.setExportLeads(this.userId, this.exportLeads);
            this.beta = user.beta;
          });
        }
      })
    );


    this.subscriptions.push(this.providerAccountService.listAll()
      .subscribe(providerAccounts => this.providerAccounts = providerAccounts)
    );

    this.profiles$ = this.profileService.listAll();
    this.shorteners$ = this.shortenerService.listAll();
    this.userService.getOperationTypes().subscribe(operation_types => this.operationTypes = operation_types);

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  toggleGeneratePassword(): void {
    const newPassword = this.generatePassword ? 'GENERATED_PASSWORD' : '';

    this.userForm.patchValue({
      password: newPassword,
      password_confirmation: newPassword
    });
  }

  onSwitchChange(value: boolean): void {
    this.exportLeads = value;
    this.UtilService.setExportLeads(this.userId, value)
  }

  openModal() {
    if (this.beta) {
      this.beta = false;
      this.modalService.open(this.modalBetaConfirmation, { ariaLabelledBy: 'Atenção!' });
    }
  }

  modalClose() {
    this.modalService.dismissAll();
    this.beta = false;
  }

  modalConfirm(email: string) {
    if (email === this.user.email) {
      this.beta = true;
      this.modalService.dismissAll();
    }
  }

  save(): void {
    const user = this.userForm.getRawValue() as User;
    user.active = this.active;
    user.credit_payment_enabled = this.creditPaymentEnabled;
    user.pix_priority = this.pixPriority;
    user.massive_sms_enabled = this.massiveSmsEnabled;
    user.revision_sms_enabled = this.revisionSmsEnabled;
    user.content_tag_enabled = this.contentTagEnabled;
    user.sms_token_validated = this.tokenVerifyEnabled;
    user.api_enabled = this.partnersAPIEnabled;
    user.analytics_sync = this.analyticsSync;
    user.fake_request = this.fakeRequest;
    user.broadcast_send_interval = this.broadcastSendInterval
    user.leads_export = this.exportLeads;
    user.beta = this.beta;

    const successMessage = user.id
      ? 'Usuário alterado com sucesso!'
      : 'Usuário inserido com sucesso!';

    if (user.commission > 12) {
      this.toastService.error('A comissão máxima é 12%.');
      return;
    }

    this.subscriptions.push(
      this.userService.save(user).subscribe(
        user => {
          let message = successMessage;
          if (user.password) {
            message += `\nNova senha: ${user.password}`;
          }
          this.toastService.success(message);
          if (!this.userId) {
            this.router.navigate(['/users/edit', user.id]);
          }
        },
        error => {
          const errorMessage = error.error?.message || 'Ocorreu um erro ao salvar o usuário.';
          this.toastService.error(errorMessage);
        }
      )
    );
  }

  confirmBetaMigration(): void {
    this.beta = true;
  }

}
