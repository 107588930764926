import { Component } from '@angular/core';
import { ADMINISTRATOR, UserService } from '../shared/services/user.service';
import { ToastService } from '../shared/components/toast/toast.service';
import { AuthService } from '../shared/services/auth.service';
import { ConfirmationService } from '../shared/components/confirmation-modal/confirmation.service';
import { User } from '../models/User';
import { Router } from '@angular/router';

@Component({
  selector: 'app-api',
  templateUrl: './api.component.html',
  styleUrls: ['./api.component.scss']
})
export class ApiComponent {
  token: string = '***************************';
  generated_at: Date = null;
  user: User;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService,
    private router: Router 
  ) {}
  
  ngOnInit(): void {
    this.user = this.authService.getUser(true);
    
    if (!this.user.api_enabled) {
      if (this.user.profile_id == ADMINISTRATOR) {
        this.toastService.warning('Você não pode acessar esta tela simulando o usuário.');
      }
      this.router.navigate(['/']);
      return;
    }
  }

  ngOnDestroy(): void {
    this.token = null;
    this.generated_at = null;
  }

  copyToken() {
    if (this.token.includes('*') || this.token == null) {
      return this.toastService.warning('Não é possível copiar a Chave de API, gere uma nova Chave de API para copiar.');
    }

    navigator.clipboard.writeText(this.token);

    this.toastService.success('Chave de API copiada para sua área de transferência!');
  }

  generateToken() {
    this.confirmationService.show('Deseja realmente gerar uma nova Chave de API?<br>Isto irá anular o sua Chave de API antiga.', () => {
      this.userService.generateApiToken().subscribe((response) => {
        this.token = response.token
        this.generated_at = response.generated_at

        this.toastService.info('Chave de API gerada com sucesso!');
        this.toastService.warning('Guarde-a em local seguro! Caso não guarde, terá que gerar uma nova Chave de API.', 15000);
      }, 
      (error) => {
        this.toastService.error(error.error.message);
      })
    })
  }
}
